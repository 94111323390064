import './App.css';
import { loadStripe } from '@stripe/stripe-js';
import Wrapper from './components/Wrapper'
import { Elements } from '@stripe/react-stripe-js';
import React, { useState, useRef } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { Link } from 'react-router-dom'
import Footer from './components/Footer'

const stripePromise = loadStripe('pk_live_51PkShDLsx4D1vQupGqQcugpVmRClxgc9D0bDU8pVpyserXYoD1J6LLsAc5cVHwrk2vX53h8kVTxP6o7ClOvPQjDY00f1qx1YXo');

function Main() {

  const [faqShown, setFaqShown] = useState(false)
  const [prices] = useState({ 30: 14, 365: 144, 730: 240, 1095: 320 });
  const [AdobePrices] = useState({ 30: 59.99, 365: 659.88, 730: 659.88 * 2, 1095: 659.88 * 3 });
  const [name, setName] = useState('');
  const [isAutoPay, setIsAutoPay] = useState(true);
  const [email, setEmail] = useState(null);
  const [duration, setDuration] = useState(30);
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  // const [isHovered2, setIsHovered2] = useState(false);
  const [formSubmittedAutoPay, setFormSubmittedAutoPay] = useState(false);
  const isMobile = useMediaQuery('(max-width: 668px)');
  const priceData = {
    30: 'price_1Pw58YLsx4D1vQup1MsD0OJW',
    365: 'price_1Pv09ALsx4D1vQupZBfBigOV',
    730: 'price_1PqIGMLsx4D1vQupm7Zj5iDR',
    1095: 'price_1PqII1Lsx4D1vQupL32u0I02',
  };

  const svgRef = useRef(null);
  // const svgRef2 = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // const handleMouseEnter2 = () => {
  //   setIsHovered2(true);
  // };

  // const handleMouseLeave2 = () => {
  //   setIsHovered2(false);
  // };

  const getSvgPosition = () => {
    if (svgRef.current) {
      const { x, y, width } = svgRef.current.getBoundingClientRect();
      return { top: y - 20, left: isMobile?(x-width-50):(x - width + 50) };
    }
    return { top: 0, left: 0 };
  };

  // const getSvgPosition2 = () => {
  //   if (svgRef2.current) {
  //     const { x, y, width } = svgRef2.current.getBoundingClientRect();
  //     return { top: y - 20, left: isMobile?(x-width-50):(x - width + 50) };
  //   }
  //   return { top: 0, left: 0 };
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {

      const agent = navigator.userAgent;

      if (isAutoPay===false || isAutoPay==='false') {
        await fetch('/.netlify/functions/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            browserAgent: agent,
            amount: prices[duration],
            name: name,
            email: email,
            duration: duration,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setClientSecret(data.clientSecret);
          });
      }
      else {
        setFormSubmittedAutoPay(true)
      }

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1c53de'
    }
  };

  const options = isAutoPay === false ? { clientSecret, appearance, business: "CheapCC" } : { mode: 'setup', currency: 'usd', appearance, business: "CheapCC" }

  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src='/images/cheapcc.png' className='logo' alt='cheapcc-logo' />
        <img alt='adobe-platinium-reseller-badge' src='/images/adobe-reseller.png' style={{ width:250, alignSelf:'center', justifySelf:'center' }} />
        {/* <div className='links-div'>
          <Link to='/reviews'>See reviews</Link>
          <a href='https://adobeking.net'>Mirror website</a>
        </div> */}
      </div>
      <div className='main'>
        {isHovered === true && (<div className='question-div' style={{ position: 'fixed', ...getSvgPosition(), zIndex: 999, width: '230px', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>If disabled, your subscription will be revoked at the end of the first period. If enabled, renewal will be automatic.<br></br>To cancel auto-pay, simply contact us.</div>)}
        {/* {isHovered2 === true && (<div className='question-div' style={{ position: 'fixed', ...getSvgPosition2(), zIndex: 999, width: '230px', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>It doesn't matter if your e-mail address is associated or not with an Adobe account when you buy your subscription on CheapAdobe.com. You can create your Adode account after your purchase.</div>)} */}
        <div className='main-main'>
          <div className='main-main-main'>
            <form onSubmit={handleSubmit} className={`inputs ${!clientSecret && formSubmittedAutoPay === false ? "shown" : ''}`}>
              {!clientSecret && formSubmittedAutoPay === false && <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
                <h1 style={{ marginBottom: 0, textAlign: 'left' }}>Create a new order</h1>
                <p>Included in our subcription is: all Adobe applications (except Substance 3D), 80GB cloud storage, Firefly AI with 250 credits renewed every month, access to beta apps, and 2 active simultaneous sessions (with 2 mobile sessions as well). You can access your suscription from anywhere on Earth, no VPN is needed.</p>
                <div className='input-container'>
                  <div className='input-div'>
                    <label>Full name:</label>
                    <div className='input-wrapper' style={{ maxWidth: 'calc(100% - 40px)', minWidth: 280 }} ><input style={{ width:'-webkit-fill-available' }} value={name} spellCheck={false} required onChange={(e) => (setName(e.target.value))} placeholder='John Smith' type='name' /></div>
                  </div>
                  <div className='input-div'>
                    <label style={{ textAlign: 'left', gap:10 }}>Adobe account email address:</label>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div className='input-wrapper' style={{ maxWidth: 'calc(100% - 40px)', minWidth: 280 }} ><input style={{ width:'-webkit-fill-available' }} value={email} spellCheck={false} required onChange={(e) => (setEmail(e.target.value))} placeholder='john.smith@gmail.com' type='email' /></div>
                    </div>
                  </div>
                  <div className='input-div'>
                    <label>Duration of your subscription:</label>
                    <select value={duration} onChange={(e) => (setDuration(parseFloat(e.target.value)))}>
                      <option disabled>Want a 3-days free trial? Contact us.</option>
                      <option value={30}>1 month</option>
                      <option value={365}>1 year</option>
                      <option value={730}>2 years</option>
                      <option value={1095}>3 years</option>
                    </select>
                  </div>
                  <div className='input-div'>
                    <label style={{ textAlign: 'left', display: 'flex', alignItems: 'center', gap: 10 }}>Enable auto-pay:<svg ref={svgRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer' }} fill="black" height="20" viewBox="0 0 15 15" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="#black"><path d="m6.74984 10.5c0 .4142.33579.75.75.75.41422 0 .75-.3358.75-.75s-.33578-.74999-.75-.74999c-.41421 0-.75.33579-.75.74999z" /><path d="m8.94995 6.25001c0-.67789-.58507-1.325-1.45-1.325-.86492 0-1.45.64711-1.45 1.325 0 .30376-.24624.55-.55.55s-.55-.24624-.55-.55c0-1.32211 1.11493-2.425 2.55-2.425 1.43508 0 2.55005 1.10289 2.55005 2.425 0 1.10382-.73004 1.64038-1.26449 1.96506-.10264.06235-.19141.1138-.26993.1593-.15058.08727-.26359.15276-.36509.23184-.06564.05115-.09133.08341-.10001.0971l-.00054.00087c-.00224.30184-.24762.54583-.54999.54583-.30375 0-.55-.24624-.55-.55 0-.46802.28211-.77268.52446-.96151.16338-.1273.38078-.25355.56398-.35994.0641-.03723.12404-.07204.176-.10361.46555-.28282.73556-.53376.73556-1.02494z" /><path clipRule="evenodd" d="m14 7.5c0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5zm-1 0c0 3.0376-2.4624 5.5-5.5 5.5-3.03757 0-5.5-2.4624-5.5-5.5 0-3.03757 2.46243-5.5 5.5-5.5 3.0376 0 5.5 2.46243 5.5 5.5z" fillRule="evenodd" /></g></svg></label>
                    <select value={isAutoPay} onChange={(e) => (setIsAutoPay(e.target.value==='true' || e.target.value===true ))}>
                      <option value={true}>Yes (free)</option>
                      <option value={false}>No, thanks</option>
                    </select>
                  </div>
                </div>
                <div>
                  <h1 style={{ textWrap: 'nowrap', width: 'fit-content' }}><span style={{ fontSize: 24 }}>$</span> {prices[duration]}.00 {duration !== 30 && <span style={{ fontSize: 14, fontWeight: 400 }}>(= $ {duration === 30 ? 14 : (duration === 365 ? 12 : (duration === 730 ? 10 : (duration === 1095 ? 9 : '???')))}.00 /month)</span>}</h1>
                  <p style={{ color: 'black' }}>Instead of ${AdobePrices[duration].toFixed(2)} on the classic subscription</p>
                  <p style={{ color: 'red' }}>You save ${(AdobePrices[duration] - prices[duration]).toFixed(2)} </p>
                </div>
                <div className='pay-button-div'>
                  <button disabled={(!email || !name) || true} type="submit"><span id="button-text">
                    {!clientSecret && formSubmittedAutoPay === false && isLoading === true ? <div className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={201} height={201} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '21.5', height: '21.5', paddingLeft: 11, paddingRight: 11, paddingTop: -2, paddingBottom: -2 }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="10" stroke="#ffffff" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div> : "Select"}</span></button>
                  <p style={{ margin: 0, color:'red' }}>Payment processor unavailable. Contact us.</p>
                </div>
                {/* <div>
              <p>Once you've filled this form and paid, you'll receive a "team invitation" email from Adobe, inviting you to join a school team subscription.</p>
              <p>Simply accept it, and you now have access to the whole Creative Cloud suite, with <b>all Adobe applications</b>.</p>
            </div> */}
              </div>}
            </form>
            <div className={`payment-container ${(isPaymentDone === false && clientSecret) || (formSubmittedAutoPay === true && isPaymentDone === false) ? "shown" : ''}`}>
              {(isPaymentDone === false && clientSecret && isAutoPay===false) && <Elements stripe={stripePromise} options={options}>
                <button onClick={() => (setClientSecret(null))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
                <Wrapper priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} clientSecret={clientSecret} />
              </Elements>}
              {(isPaymentDone === false && isAutoPay === true && formSubmittedAutoPay === true) && <Elements stripe={stripePromise} options={options}>
                <button onClick={() => (setFormSubmittedAutoPay(false))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
                <Wrapper priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} />
              </Elements>}
            </div>
            <div className={`payment-container-done ${isPaymentDone === true && isLoading === false ? "shown" : ''}`}><div className='success-div'><div class="animation-ctn">
              <div class="icon icon--order-success svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
                  <g fill="none" stroke="#22AE73" stroke-width="2">
                    <circle cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{ strokeDasharray: '100px, 100px', strokeDashOffset: 200 }} />
                  </g>
                </svg>
              </div>
            </div>{isPaymentDone===true && isLoading===false && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{isPaymentDone===true && isLoading===false && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{isPaymentDone===true && isLoading===false && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{isPaymentDone===true && isLoading===false && <h3 style={{ textAlign: 'center', marginTop: 20 }}>problem@cheapcc.net</h3>}</div></div>
          </div>
          <div className={`faq ${faqShown ? 'shown' : ''}`}>
            <div onClick={() => (setFaqShown(!faqShown))} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', width: '100%', alignItems: faqShown ? 'flex-start' : 'center', justifyContent: 'space-between' }}>
              <h1 className={`faq-title ${faqShown ? 'withMargin' : ''}`}>FAQ</h1>
              <svg style={{ transition: '0.1s', transform: faqShown ? 'rotate(90deg)' : '' }} fill="#000000" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 330 330">
                <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
                c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
                C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
                C255,161.018,253.42,157.202,250.606,154.389z"/>
              </svg>
            </div>
            <div className={`faq-text ${faqShown ? 'shown' : ''}`}>
              <h3 style={{ textAlign: 'left' }}>{faqShown ? "What is CheapCC.net?" : ""}</h3>
              <p>{faqShown ? "CheapCC.net is a site where you can legally get the genuine Adobe CC All Apps plan applied to your own Adobe account, at a much lower price." : ""}</p>
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Where does the subscription come from?" : ''}</h3>
              <p style={{ marginBottom: 5 }}>{faqShown ? "In an attempt to gain a foothold in China, Adobe is giving away almost free subscriptions to resellers for distribution to individuals and professionals. Many administrators have started trying to resell them to Westerners. But because they're in China, they have an embargo with many Western countries, so it's very complicated. Most of the time, they can only sell on Chinese marketplaces that are difficult to access from our Western countries." : ""}</p>
              <p style={{ marginBottom: 5 }}>{faqShown ? "CheapCC is in contact with the best resellers in China, and acts as a secure gateway hosted in the US, offering the best possible prices on the most stable subscriptions on the market. " : ""}<b>All our suppliers are Adobe-certified Gold or Platinum resellers.</b></p>
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Can my subscription be revoked by Adobe?" : ""}</h3>
              <p>{faqShown ? "The subscriptions we have are geo-unlocked. It means they're not like the Turkish ones that you have to pay with a VPN. It means they can legally be used from anywhere in the world, without violating any terms of service or conditions of use." : ""}</p>
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Can my subscription be cancelled?" : ""}</h3>
              <p>{faqShown ? "Our resellers have been around for years and are well known in the industry. They guarantee the full duration for which you paid. Our support team is available daily to address any inquiries or issues, providing replacement subscriptions or refunds if necessary." : ""}</p>
              {faqShown && <Link style={{ textAlign: 'left', display: 'flex', marginLeft: 10, width: '100%', color: '#596B76' }} to='/refund-policy'>See our refund policy</Link>}
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Does anyone has access to my content?" : ""}</h3>
              <p>{faqShown ? "We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:" : ""}</p>
              {faqShown && <a style={{ textAlign: 'left', display: 'flex', marginLeft: 10, width: '100%', color: '#596B76' }} href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'>blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use</a>}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Main;
