import { Route, Routes, Navigate } from "react-router-dom"
import React from "react";
import Main from "./Main";
import Refund from "./Refund";
import TermsOfService from "./TermsOfService";
import PrivacyNotice from "./PrivacyNotice";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/refund-policy" element={<Refund />} />
      <Route path="/refund" element={<Refund />} />
      <Route path="/privacy-notice" element={<PrivacyNotice />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}